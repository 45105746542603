.header-logo {
    svg {
        width: 200px;

        @media (max-width: 575.98px) {
            width: 180px;
        }
    }
}

.header__hamburger {
    svg {
        width: 24px;
        height: 24px;
        
        path {
            stroke-width: 1px;
        }

        cursor: pointer;
    }
}

.semi-navigation-horizontal {
    @media (max-width: 575.98px) {
        padding: 0 8px !important;
    }
}

.semi-navigation-footer {
    @media (max-width: 575.98px) {
        padding: 16px 10px;
    }
}