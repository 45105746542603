.btn-remove {
    color: var(--semi-color-danger) !important;
}

.btn-summit, .btn-cancel {
    padding: 5px 20px;
}


.btn-group {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin: 0 10px;
    }
}