.sideBar {
    height: 100%;

    .semi-navigation-list {
        .semi-navigation-sub {
            &> li.semi-navigation-item {
                .semi-navigation-sub-title {
                    .semi-navigation-item-text {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}