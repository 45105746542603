$motion-overlay-timing: 2.5s;
$motion-logo-timing: $motion-overlay-timing - 1;
$motion-main-timing: calc($motion-overlay-timing / 2);

.form-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #1F9A2D;

  text-align: center;

  .form-header {
    left: 50%;
    top: -12px;
    z-index: 3;
    position: absolute;
    transform: translate(-50%, -50%);

    .logo__icon-wrapper {
      filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.4));
    }

    .logo__slogan {
      fill: #022240;

      animation: slogan_motion $motion-overlay-timing cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1;
    }

    animation: logo_motion $motion-logo-timing linear 0s 1;
  }

  .form-wrapper {
    z-index: 1;
    position: relative;
    transform: translateY(50%);
  }

  .form-box {
    width: 320px;
    margin: auto;
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: center;
    transform-origin: center;

    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0px 10px 0 #0a310f;

    animation: box_motion $motion-main-timing ease-in 0s 1;



    .form-overlay {
      left: 0;
      height: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      border-radius: 8px;
      background-color: #1F9A2D;

      animation: overlay_motion $motion-overlay-timing ease-out 0s 1;
    }
  }

  .form-content {
    width: 100%;
  }

  .semi-form-field-label-text {
    color: #022240;

    &::after {
      display: none;
    }
  }

  .semi-input-wrapper {
    color: #022240;
    padding: 4px 2px;
    height: auto;
    border-radius: 5px !important;
    box-shadow: inset 0 0 0 1px #022240;

    &:hover {
      border: 1px solid #022240;
    }
  }

  .form-checkout-savedUserPassword {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin: 5px 0 20px;

    &.disabled {
      opacity: .8;
      cursor: not-allowed;
      pointer-events: none;
    }

    .check-input {
      width: 12px;
      height: 12px;
      border-radius: 3px;
      border: 1px solid #022240;
      margin: 0 5px;
      transform-origin: center;
      transform: scale(1);
      transition: transform 0.2s;
      position: relative;
      cursor: pointer;

      &:hover {
        transform: scale(0.8);
        transition: transform 0.2s;
      }

      .check-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
        transition: visibility 0.1s;

        &.checked {
          visibility: visible;
          transition: all 0.1s;
        }

        svg {
          font-size: 24px;
        }
      }
    }

    .check-label {
      font-size: 14px;
      color: #022240;
      cursor: pointer;
    }
  }

  .form-login-button {
    color: #fff;
    height: auto;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 100px #022240;
    transition: box-shadow 0.3s, color 0.3s;

    span {
      display: flex;
      column-gap: 5px;
      align-items: center;
      justify-content: center;

      .semi-spin-middle {
        .semi-spin-wrapper {
          top: 50%;
          height: 100%;
        }
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 2px #022240;
      color: #022240;

      transition: all 0.3s;

      .semi-spin-wrapper {
        color: #022240;
      }
    }

    .semi-spin-wrapper {
      color: #fff;
      top: 80%;
    }


  }

  .form-overlay-sun {
    left: 50%;
    z-index: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255);

    @media only screen and (max-width: 575.98px) {
      left: 100%;
    }

  }

  .container-overlay {
    @for $i from 1 through 4 {
      $width: 100%+$i * 10%;
      $opacity: 0.1;

      .form-overlay-sun:nth-of-type(#{$i}) {
        width: $width;
        opacity: $opacity;
        height: calc(80vh + #{$i} * 100px);
        animation: sun_motion $motion-overlay-timing + calc($i / 2) linear 0s 1;

        @media only screen and (max-width: 575.98px) {
          width: $width + 80%;
        }

      }
    }
  }

}

@media only screen and (max-width: 991.98px) {
  .form-box {
    width: 40% !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .form-box {
    width: 80% !important;
  }
}


@keyframes logo_motion {

  0% {
    top: 50%;
    opacity: 0;
  }

  60% {
    top: 50%;
    opacity: 1;
  }

  90% {
    top: 50%;
    opacity: 1;
  }

  100% {
    top: -11px;
    opacity: 1;
  }
}

@keyframes slogan_motion {

  0% {
    fill: #fff;
  }

  99% {
    fill: #fff;
  }

  100% {
    fill: #022240;
  }
}

@keyframes overlay_motion {

  0% {
    height: 100%;
  }

  90% {
    height: 100%;
  }

  92% {
    height: 80%;
  }

  94% {
    height: 60%;
  }

  96% {
    height: 40%;
  }

  98% {
    height: 20%;
  }

  100% {
    height: 0;
  }
}

@keyframes box_motion {

  0% {
    box-shadow: 0 0px 0px 0 #1F9A2D;
  }

  50% {
    box-shadow: 0 0px 0px 0 #198125;
  }

  89% {
    box-shadow: 0 0px 20px 0 #156e20;
  }

  90% {
    box-shadow: 0 0px 20px 0 #0a310f;
  }

  100% {
    box-shadow: 0 0px 10px 0 #0a310f;
  }

}

@keyframes sun_motion {
  0% {
    height: 0;
    width: 0;
  }

  90% {
    height: 0;
    width: 0;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}