@mixin flex($justify-content, $align-item) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin smallLaptop {
  @media only screen and (max-width: $small-laptop) {
    @content;
  }
}

@mixin maxResponsive($screen) {
  @if (map-has-key($map: $breakpoints, $key: $screen)) {
    $value: map-get(
      $map: $breakpoints,
      $key: $screen,
    );
    @media screen and (max-width: $value) {
      @content;
    }
  } @else {
    @warn "màn hình chưa khai báo";
  }
}
@mixin minResponsive($screen) {
  @if (map-has-key($map: $breakpoints, $key: $screen)) {
    $value: map-get(
      $map: $breakpoints,
      $key: $screen,
    );
    @media screen and (min-width: $value) {
      @content;
    }
  } @else {
    @warn "màn hình chưa khai báo";
  }
}

@mixin fixHeightSignIn {
  @media only screen and (max-height: 660px) {
    @content;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}
