.transform {
    transform: translateX(0);
    transition: transform .2s;
    animation: slideLeftToRight 0.5s linear 0s 1;  
}

@keyframes slideLeftToRight {
    0% {
        transform: translateX(-200%);
    };

    100% {
        transform: translateX(0);
    }
}