:root {
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.error-red {
  color: red;
}

a {
  text-decoration: none;
  color: unset;
}

.header-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-logo-text {
  color: #022240 !important;
  font-weight: 900 !important;
}

.header-logo-text.dark {
  color: #fff !important;
}

/* side bar */
.sidebar-header {
  font-size: 16px;
  font-weight: bold;
}

.semi-navigation-list li.semi-navigation-item .semi-navigation-item-text {
  font-size: 16px;
  font-weight: bold;
}

.semi-navigation-sub li.semi-navigation-item .semi-navigation-item-text {
  font-size: 14px;
  font-weight: 400;
}

.developer-mode {
  display: none;
}

.semi-navigation-item-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 10px 0;
}

/* semi design CSS */
.semi-table-pagination-info {
  /* visibility: hidden; */
  display: none;
}
.App .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
  padding: 0.5rem !important;
  text-align: center;
}
.App .semi-table-thead > .semi-table-row > .semi-table-row-head {
  text-align: center;
}
.semi-navigation-sub .semi-navigation-item {
  font-size: 0.775rem;
}
.semi-page-switch {
  position: absolute;
  top: -2.5rem;
  left: 0;
}
/* //updateAvatar */
.imageUpload {
  text-align: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  border: 5px solid #eee;
}
.imageUpload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* end */

.createNewsForm input {
  width: 90%;
  padding: 5px 10px;
  font-size: 15px;
}
.createNewsForm select {
  height: 30px;
  width: 130px;
  font-size: 15px;
}

.createNewsForm span {
  color: red;
  font-size: 15px;
}

.createNewsForm button {
  padding: 10px 20px;
  background-color: #0077fa;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.createNewsForm button:hover {
  opacity: 0.8;
  transition: all 0.5s;
}

.main-route {
  /* background: var(--bgMainRoute, white); */
}

/* @media only screen and (max-width: 991px) {
  .main-route {
    padding: 0.5rem;
  }
} */

/* custom ColorPicker */

.MuiBox-root .makeStyles-container-15,
.MuiBox-root .makeStyles-container-147,
.MuiBox-root .makeStyles-colorboxHsvGradient-16 {
  width: 100%;
  margin: auto;
}
.MuiBox-root .makeStyles-container-2 {
  overflow: visible;
}

/* custom upload image */
.uploadImage .semi-upload-file-list {
  width: 100%;
}
.uploadPreviewImage .semi-upload-file-list {
  display: none !important;
}
.uploadImage .semi-upload-file-list .semi-upload-add,
.uploadImage .semi-upload-add {
  display: none !important;
}

.newsContent {
  padding: 20px;
  background-color: #fcfcfc;
}
.newsContent img {
  width: 100%;
  height: auto;
}
.text-green {
  color: rgb(6, 183, 155);
}
.locationPoint svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
/* .semi-tag.semi-tag-large.semi-tag-light.semi-tag-white-light.semi-tag-closable{
  display: none;
} */

/* document  */
.document-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.document-item {
  padding: 10px;
  border: 1px solid var(--semi-color-text-0);
  border-radius: 5px;
  width: 20%;
  margin: 10px calc((90% - 4 * 20%) / 8);
  position: relative;
  color: var(--semi-color-text-0);
  background-color: var(--semi-color-bg-0);
}
.document-icon {
  background-color: var(--semi-color-primary-light-default);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
}
.document-item h4 {
  margin: 5px 0;
}
.document-item p {
  margin: 3px 0;
  font-size: 15px;
}
.document-setting {
  position: absolute;
  top: 15px;
  right: 20px;
  color: var(--semi-color-text-0);
  width: 20px;
  height: 20px;
  align-items: middle;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.document-setting:hover {
  background-color: var(--semi-color-bg-1);
}
.document-action {
  position: absolute;
  top: 30px;
  right: 20px;
  max-height: 0%;
  width: fit-content;
  padding: 0;
  background-color: var(--semi-color-bg-2);
  border-radius: 5px;
  overflow-y: hidden;
  transition: max-height 0.3s;
}
.document-action.show {
  max-height: 100%;
  transition: max-height 0.3s;
  z-index: 120;
}
.document-action p {
  padding: 1px 10px;
  cursor: pointer;
  margin: 0;
  /* color: var(--semi-color-text-2); */
  color: var(--semi-color-text-0);
}

.document-action p:first-of-type {
  border-bottom: 1px solid #eee;
}

.document-action p:hover {
  background-color: #4e2ffc;
  color: #fff;
}

.document-item .confirm.show {
  max-height: 100%;
  padding: 10px;
}
.document-item .confirm .btn {
  margin: 0 10px;
}

.document-item .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.document-item .confirm {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  text-align: center;
  padding: 0 10px;
  background-color: var(--semi-color-bg-1);
  max-height: 0;
  overflow: hidden;
  transition: max-height, padding 0.3s;
  z-index: 130;
}

.hotelUploadImage .image-item {
  display: flex;
  margin: 10px 10px 10px 0px;
}

.hotelUploadImage .image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}

.hotelUploadImage .image-item__btn-wrapper button {
  height: 60px;
  background-color: rgb(245, 244, 244);
  box-shadow: 0 8px 24px hsl(210deg 8% 62% / 20%);
  border-radius: 2px;
  border: 0;
}

.hotelUploadImage .image-item__btn-wrapper button:hover {
  background-color: rgb(229, 227, 227);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.formHotelImages .semi-upload-file-list-main {
  margin-top: 8px;
}

.mapSearchInput {
  position: absolute;
  top: 42px;
  left: 12px;
  z-index: 2;
  width: 450px;
  padding: 10px;
  border: 0;
}

.mapSearchInput:focus {
  border: 0;
  outline: none;
}

.iconAddTent svg {
  margin-bottom: 3px;
  margin-left: 3px;
  cursor: pointer;
}

.sidebar.semi-layout-sider {
  display: contents !important;
}

.siderIcon {
  display: flex;
  align-items: center;
}

.siderSubIcon {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.iconPer {
  margin: 0 4px;
}

.perHR {
  border: 1px solid rgb(234, 234, 234);
  margin: 20px 0;
}

.perName {
  font-size: 15px;
}

.semi-table {
  width: 100% !important;
}

.semi-dropdown-item {
  justify-content: center !important;
}

.ck-editor__editable,
.cke_reset {
  min-height: 400px !important;
}
