.side-bar {
    width: 240px;
    transition: width .2s;

    &.hide {
        width: 0;
        overflow: hidden;

        transition: width .2s;
        background-color: transparent;
    }
}

.main-content {
    padding: 20px;
    min-height: 85vh;
    background-color: var(--semi-color-bg-0);

    @media (max-width: 575.98px) {
        padding: 8px;
    }
}

.mobile-view-toggle {
    transform-origin: left;

    .main-route {
        width: calc(100% + 240px);
    }
}