.table-dropdown-action {
    .semi-dropdown {
        .semi-dropdown-item {
            padding: 4px 8px;

            button {
                background: transparent !important;
            }
        }
    }
}