/* PC */
@media only screen and (min-device-width: 1224px) {
  .semi-navigation.semi-navigation-vertical {
    height: 100%;
    display: block !important;
  }
  .responsiveBar {
    display: none;
  }
}

@media only screen and (max-width: 575.89px) {
 .semi-modal{
  width: 95% !important;
 }

 .semi-modal-content{
  padding: 8px;
 }
}


