.semi-portal {
  .semi-tooltip-wrapper {
    background-color: var(--semi-color-nav-bg);
    color: var(--semi-color-text-0);
  }
  .semi-tooltip-icon-arrow {
    color: var(--semi-color-nav-bg);
  }
}

.table-button-action-delete {
  svg {
    color: var(--semi-color-danger);
  }
}

.nameColumn {
  cursor: pointer;
}

.semi-table-pagination-outer {
  overflow: auto;
}
