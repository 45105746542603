.form_button {
  margin: 10px 0;

  button {
    margin: 0 10px;
  }
}

.semi-form-field-label-text {
  &::after {
    display: none;
  }
}

.semi-col-24 {
  @include mobile {
    width: 100%;
  }
}

.semi-col-11 {
  @include mobile {
    width: 100%;
  }
}


.mapSearchInput {
  width: 80%;
}

.responsiveBar {
  display: none;
}
