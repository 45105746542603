// .semi-input-wrapper-default {
//   background: #fff;
//   border: 2px solid #ecdede;
//   border-radius: 7px;
// }

// .semi-input-wrapper .semi-icon {
//   color: #43b8f7;
// }

.bell-qty {
  position: absolute;
  top: -18%;
  right: 7%;
  background: #ee786c;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
}

.header-avatar {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .avatar-img {
    width: 40px;
    height: 40px;
    @include mobile {
      width: 30px;
      height: 30px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .avatar-user {
    margin-left: 10px;
    color: #0f123f;
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
      color: #fff;
    }
    h5,
    h6 {
      margin: 0 !important;
    }
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 3px !important;
      color: #ecf0f1;
      @include tablet {
        font-size: 13px !important;
        white-space: nowrap;
      }
    }
    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .dropdown__information {
    position: absolute;
    top: 150%;
    right: 0;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #d9dee4;
    width: 220px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
    @include mobile {
      width: 200px;
    }
    &.active {
      opacity: 1;
      visibility: visible;
      top: 100%;
      transition: all 0.25s;
    }
    div {
      padding: 12px 20px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #d9dee4;
      }
    }
  }
}

.header-logout {
  margin-left: 50px;
  cursor: pointer;
  // @include tablet {
  //   display: none;
  // }
  &:hover {
    opacity: 0.7;
  }
}

.semi-navigation {
  overflow: unset;
}

.semi-navigation-horizontal {
  @include tablet {
    padding: 0 15px;
  }
  @include mobile {
    height: 70px;
  }
}

.layout {
  display: flex;
  height: 100vh;
  @include tablet {
    display: unset;
  }
  // align-items: center;
  &__left {
    position: relative;
    width: 0%;
    transition: width 0.3s;
    z-index: 10;
    @include tablet {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 0% !important;
      height: 100vh;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      &.active {
        width: 250px !important;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
      }
    }
    &.active {
      // width: 18%;
      width: 250px;
      transition: width 0.3s;
      @include minResponsive(max-width) {
        width: 280px;
      }
    }
    &-logo {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // min-width: 250px;
      // height: 80px;
      // background: #2a3f54;
      // padding-left: 15px;
      box-sizing: border-box;
      a {
        width: 45%;
        height: 100%;
        display: block;
        @include mobile {
          width: 40%;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include mobile {
          height: 100%;
        }
      }
      @include mobile {
        height: 70px;
      }
      .icon__close {
        position: absolute;
        top: 50%;
        right: -16%;
        z-index: 10000;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        background: #4abeeb;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 10px 10px 0;
        display: none;
        cursor: pointer;
        @include tablet {
          display: flex;
        }
        svg {
          color: #fff;
        }
      }
    }
  }
  &__right {
    // flex-grow: 1;
    width: 100%;
    overflow: auto;
    transition: width 0.3s;
    &.active {
      // width: 82%;
      width: calc(100% - 250px);
      transition: width 0.3s;

      @include minResponsive(max-width) {
        width: calc(100% - 280px);
      }
      @include tablet {
        width: 100%;
      }
    }
  }
  .blurOpacity {
    @include tablet {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
      width: calc(100vw - 250px);
      height: 100vh;
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      transition: 0.25s all;
      cursor: pointer;
      &.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        transition: 0.25s all;
      }
    }
  }
}

// .semi-button.semi-button-with-icon {
//   @include tablet {
//     display: none;
//   }
// }
